export const extensionToLanguage = {
  py: 1,  // Python
  java: 2,  // Java
  cpp: 3,  // Cpp
  rb: 4,  // Ruby
  cs: 5,  // Csharp
  js: 6,  // javascript
  kt: 7,  // Kotlin
  m: 8,  // Objective-C
};

export const languageMap = [
  { syntaxName: "python", name: "Python", extension: ".py" },
  { syntaxName: "java", name: "Java", extension: ".java" },
  { syntaxName: "cpp", name: "C++", extension: ".cpp" },
  { syntaxName: "ruby", name: "Ruby", extension: ".rb" },
  { syntaxName: "csharp", name: "C#", extension: ".cs" },
  { syntaxName: "javascript", name: "JavaScript", extension: ".js" },
  { syntaxName: "kotlin", name: "Kotlin", extension: ".kt" },
  { syntaxName: "objectivec", name: "Objective-C", extension: ".m" },
  // add more languages here
];

export const nameToLanguage = {
  "Unknown": 0,
  "Python": 1,  // Python
  "Java": 2,  // Java
  "C++": 3,  // Cpp
  "Ruby": 4,  // Ruby
  "C#": 5,  // Csharp
  "JavaScript": 6,  // javascript
  "Kotlin": 7,  // Kotlin
  "Objective-C": 8,  // Objective-C
};