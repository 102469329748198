import React from 'react';
import AccountDetails from '../components/AccountDetails';

const AccountPage = () => {
  return (
    <AccountDetails/>
  );
}

export default AccountPage;
